import U from './lib-utils';

/**
 * 
 * @param {string} tpl  template
 * @returns {renderer}
 */
function renderer(tpl) {
    return (renderer.is(this) ? this.init : renderer.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(renderer).prototype;

P._renderer__template = null;
P._renderer__object = null;
P.init = function (tpl) {
    var rt = [];
    var last_offset = 0;
    var self = this;
    U.NEString(tpl, '').replace(/(\{\{[^}]{1,}\}\})/g, function () {
        var rags = Array.prototype.slice.call(arguments);
        var match = rags[0];
        var offset = rags[rags.length - 2];
        var whole = rags[rags.length - 1];
        rt.push(whole.substring(last_offset, offset));
        last_offset = offset + match.length;
        var clean_match = match.replace(/^\{{0,}/g, '').replace(/\}{0,}$/g,'');
        rt.push((function () {
            return self._renderer_get_value(clean_match);
        }));
    });
    this._renderer__template = rt;
    return this;
};


P._renderer_get_value = function (match_name) {
    var fn = ['render_', match_name.toLowerCase().replace(/\s/g, '_')].join('');
    if (U.is_object(this._renderer__object) && U.is_callable(this._renderer__object[fn])) {
        return this._renderer__object[fn]();
    }
    return '';
};


P.render = function (robj) {
    this._renderer__object = U.safe_object(robj);
    var result = [];
    for (var i = 0; i < this._renderer__template.length; i++) {
        if (U.is_callable(this._renderer__template[i])) {
            result.push(this._renderer__template[i]());
        } else {
            result.push(this._renderer__template[i]);
        }
    }
    return result.join('');
};

P.render_dom = function (robj) {
    var html = this.render(robj);
    var div = document.createElement('div');
    div.innerHTML = html;
    if (div.children.length !== 1) {
        throw new Error('lib-simple-renderer: template MUST produce exactly one dom node for `render_dom` method');
    }
    var result = div.children.item(0);
    div = null;
    return result;
};


export default renderer;