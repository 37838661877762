<template>
    <div class="application-content-element"><router-view></router-view></div>    
</template>
<script>   
    export default {
        name: 'App',        
        mounted: function () {            
            window.aaa = this;
        },
        components: {           
        },
        data: function () {
            return {
                preloader: {
                    global: false,
                    visible: true
                }
            };
        }
    }
</script>

<style>
    #app {     
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
