import U from './lib-utils';
import {request} from './lib-networking';
import {callback} from './lib-callback-manager';
import { createApp } from 'vue';
import {global_preloader} from './lib-global-preloader';

var H = null;

function translator() {
    return translator.is(H) ? H : ((translator.is(this) ? this.init : translator.F).apply(this, Array.prototype.slice.call(arguments)));
}
var P = U.fixup_constructor(translator).prototype;

P.lang = null;
P.section = null;
P.terms = null;
P.terms_to_translate = null;
P.handle = null;
P.dialog = null;
P._ready_callbacks = null;
P.UPTO = null;
P.init = function (section) {
    H = this;
    this._ready_callbacks = [];
    this.section = U.NEString(section, null);
    if (!this.section) {
        throw new Error('lib-translator: section name required');
    }
    this.lang = U.NEString(localStorage.getItem(['translator-', this.section, '-selected-language'].join('')), null);
    if (!this.lang) {
        this.run_language_selector();
    } else {
        this.load_lang_local();
    }
    window.translator_instance = this;
    return this;
};

P.load_lang_local = function () {
    try {
        var terms_s = localStorage.getItem(['translator', this.section, this.lang, 'terms'].join('-'));
        if (terms_s) {
            var terms_o = JSON.parse(terms_s);
            if (U.is_object(terms_o)) {
                this.terms = terms_o;
            }
        }
    } catch (e) {
        this.terms = null;
    }
    if (!this.terms) {
        this.force_load();
    } else {
        this.silent_load();
        this.resolve();
    }
    return this;
};

P.resolve = function () {
    var c = U.safe_array(this._ready_callbacks);
    this._ready_callbacks = {
        push: function () {
            var args = Array.prototype.slice.call(arguments);
            for (var i = 0; i < args.length; i++) {
                if (callback.is(args[i])) {
                    try {
                        args[i].run();
                    } catch (e) {
                        window.setTimeout(function () {
                            throw e;
                        }, 0);
                    }
                }
            }
        }
    };
    this._ready_callbacks.push.apply(this._ready_callbacks, c);
    return this;
};

P.ready = function (co, ca) {
    this._ready_callbacks.push(callback(co, ca));
    return this;
};


P.silent_load = function () {
    var last_uptime = U.intMoreOr(localStorage.getItem(['translator', this.section, this.lang, 'uptime'].join('-')), 0, 0);
    var delta = (new Date()).getTime() - last_uptime;
    if (delta > U.intMoreOr(process.env.VUE_APP_LIB_EVE_TRANSLATOR_UPTIME_INTERVAL, 0, 24 * 60 * 60 * 1000)) {
        window.setTimeout(this.load_language_data.bindTo(this), 10000);
    }
    return this;
};

P.force_load = function () {
    global_preloader().show().set_text('loading language package....');
    this.load_language_data().always(global_preloader(), global_preloader().hide).always(this, this.try_reload);
    return this;
};

P.try_reload = function () {
    if (this.terms) {
        this.resolve();
    }
};

P.add_term_to_translations = function (term) {
    var cterm = U.NEString(term, null);
    if (cterm) {
        if (!U.is_array(this.terms_to_translate)) {
            this.terms_to_translate = [];
        }
        this.terms_to_translate.push(term);
        if (this.UPTO) {
            window.clearTimeout(this.UPTO);
            this.UPTO = null;
        }
        this.UPTO = window.setInterval(this.send_terms.bindTo(this), 10000);
    }
    return this;
};

P.install = function (app, options) {
    app.config.globalProperties.TT = this.translate.bindTo(this);
    //app.provide('TT',this.translate.bindTo(this));

};
P.send_terms = function () {
    var c = U.safe_array(this.terms_to_translate);
    this.terms_to_translate = [];
    if (this.UPTO) {
        window.clearTimeout(this.UPTO);
        this.UPTO = null;
    }
    var send_terms_url = U.NEString(process.env.VUE_APP_LIB_EVE_TRANSLATOR_POST_URI, null);
    if (send_terms_url) {
        if (c.length) {
            this._terms_in_transition = c;
            request('POST', process.env.VUE_APP_LIB_EVE_TRANSLATOR_HOST+send_terms_url, {
                language: this.lang,
                section: this.section,
                terms: c,
                secretKey: U.NEString(localStorage.getItem('lib-translator-post-secret-key'), null)
            }, 'json', false, true)
                    .fail(this, this.on_post_fail)
                    .done(this, this.on_post_done);
        }
    }
};
P.on_post_fail = function () {
    if (U.is_array(this._terms_in_transition)) {
        for (var i = 0; i < this._terms_in_transition.length; i++) {
            this.terms_to_translate.push(this._terms_in_transition[i]);
        }
    }
};
P.on_post_done = function (d,p) {
    if (U.is_object(d.parsed_response)) {
        if (d.parsed_response === 'ok') {
            return this;
        }
    }
    return this.on_post_fail();
};
P.translate = function (term) {
    if (!this.terms.hasOwnProperty(term)) {
        this.terms[term] = term;
        this.add_term_to_translations(term);
    }

    return this.terms[term];
};
P.load_language_data = function () {
    var host = process.env.VUE_APP_LIB_EVE_TRANSLATOR_HOST;
    var url = process.env.VUE_APP_LIB_EVE_TRANSLATOR_LANGUAGE_URI.replace(/%section%/g, this.section).replace(/%language%/g, this.lang);
    return request('GET', host + url, null, null, false, true).done(this, this.on_lang_load_response);
};
P.on_lang_load_response = function (rq,p) {
    if (U.is_object(rq.parsed_response)) {
        if (rq.parsed_response.status === 'ok') {
            var terms = U.safe_object(rq.parsed_response['language-pack']);
            if (!this.terms) {
                this.terms = {};
                for (var k in terms) {
                    if (terms.hasOwnProperty(k) && (typeof (terms[k]) === 'string')) {
                        if (terms[k] === '--empty--') {
                            this.terms[k] = '';
                        } else {
                            this.terms[k] = U.NEString(terms[k], '');
                        }
                    }
                }
                localStorage.setItem(['translator', this.section, this.lang, 'terms'].join('-'), JSON.stringify(this.terms));
                localStorage.setItem(['translator', this.section, this.lang, 'uptime'].join('-'), (new Date()).getTime());
            }
        }
    }
};
P.append_handle = function () {
    var bds = document.getElementsByTagName('body');
    if (bds.length) {
        bds[0].appendChild(this.handle);
    }
};
P.on_language_selected = function (lang) {
    localStorage.setItem(['translator-', this.section, '-selected-language'].join(''), lang);
    this.lang = lang;
    this.dialog.unmount();
    this.dialog = null;
    this.handle.remove();
    this.load_lang_local();
};
P.on_component_load_fullfiled = function (m) {
    require('@/assets/css/popups.css');
    global_preloader().hide();
    var app = createApp(m.default);
    if (!this.handle) {
        this.handle = document.createElement('div');
        this.handle.classList.add('popup-static-wrapper');
        this.handle.classList.add('language-selector-popup-static-wrapper');
        this.handle.style.display = 'none';
    }
    this.append_handle();
    this.handle.style.display = 'block';
    app.mount(this.handle).set_callback(this, this.on_language_selected);
    this.dialog = app;
};
P.run_language_selector = function () {        
    import('./components/language-selector').then(this.on_component_load_fullfiled.bindTo(this));
};
export {translator};