import U from './../lib-utils';
import {Value, InvalidValue, EmptyValue} from './Value';

function abstractFilter() {
    throw new Error('lib-filter: abstractFilter is abstract');
}

var P = U.fixup_constructor(abstractFilter).prototype;

P.init = function () {
    return this;
};

P.apply = function (current_value, params, field_name) {
    if (this.is_default() && this.isValue(current_value)) {
        return this.get_default(current_value, params, field_name);
    } else if (this.is_default() && !this.isValue(current_value)) {
        return current_value;
    } else if (!this.is_default() && this.isValue(current_value)) {
        return current_value;
    }
    return this.apply_filter(current_value, params, field_name);
};

P.get_default = function (current_value, params, field_name) {
    throw new Error('lib-filter: abstractFilter::get_default is abstract');
};

P.apply_filter = function (current_value, params, filed_name) {
    throw new Error('lib-filter: abstractFilter::apply_filter is abstract');
};

P.is_default = function () {
    return false;
};

/**
 * true if at least one of arguments is a Value
 * @returns {Boolean}
 */
P.isValue = function () {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < args.length; i++) {
        if (Value.is(args[i])) {
            return true;
        }
    }
    return false;
};

P.is_value = P.isValue;

/**
 * 
 * @param {String} field_name
 * @returns {EmptyValue}
 */
P.create_empty_value = function (field_name) {
    return EmptyValue(field_name);
};

/**
 * 
 * @param {String} field_name
 * @param {String} message
 * @returns {InvalidValue}
 */
P.create_invalid_value = function (field_name, message) {
    return InvalidValue(field_name, message);
};


export {abstractFilter};