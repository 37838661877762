<template>
    <div class="loader-component-wrapper-local" v-show="visible">
        <div class="loader-component-local">
            <div class="loader-element-local">
                <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" viewBox="0 0 128 128" xml:space="preserve"><g><path d="M59.6 0h8v40h-8V0z" fill="#000000" fill-opacity="1"></path><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" fill-opacity="0.2" transform="rotate(30 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" fill-opacity="0.2" transform="rotate(60 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" fill-opacity="0.2" transform="rotate(90 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#cccccc" fill-opacity="0.2" transform="rotate(120 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#b2b2b2" fill-opacity="0.3" transform="rotate(150 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#999999" fill-opacity="0.4" transform="rotate(180 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#7f7f7f" fill-opacity="0.5" transform="rotate(210 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#666666" fill-opacity="0.6" transform="rotate(240 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#4c4c4c" fill-opacity="0.7" transform="rotate(270 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#333333" fill-opacity="0.8" transform="rotate(300 64 64)"></path><path d="M59.6 0h8v40h-8V0z" fill="#191919" fill-opacity="0.9" transform="rotate(330 64 64)"></path><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="960ms" repeatCount="indefinite"></animateTransform></g></svg>
            </div>
        </div>
    </div>
</template>
<style   scoped>
    .loader-component-wrapper-local {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .loader-component-local {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        background: rgba(100,100,100,.3);
    }

    .loader-element-local {
        width: 2em;
        height: 2em;
        line-height: 0;
        pointer-events: none;
    }

    .loader-element-local svg {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
</style>
<script>
    export default {
        name: 'preloader',
        data: function () {
            return {visible: false, counter: 0};
        },
        methods: {
            show: function () {
                this.$.data.counter += 1;
                if (this.$.data.counter) {
                    this.$.data.visible = true;
                }
            },
            hide: function () {
                this.$.data.counter -= 1;
                if (this.$.data.counter < 0) {
                    this.$.data.counter = 0;
                }
                if (this.$.data.counter === 0) {
                    this.$.data.visible = false;
                }
            }
        }
    }
</script>

