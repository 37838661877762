import U from './lib-utils';
import template from '!!raw-loader!./templates/lib-global-preloader/template.html';
import css_style from '!!raw-loader!./templates/lib-global-preloader/style.css';
var H = null;
function global_preloader() {
    return global_preloader.is(H) ? H : ((global_preloader.is(this) ? this.init : global_preloader.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(global_preloader).prototype;

var handle = null;
var counter = 0;
var text_handle = null;
var text_container = null;

function prepend_style() {
    var headers = document.getElementsByTagName('head');
    var header_element = null;
    if (headers.length) {
        header_element = headers.item(0);
    } else {
        header_element = document.documentElement;
    }
    var style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'lib-global-preloader-style';
    style.innerText = css_style;
    header_element.prepend(style);
}
function append() {
    var bodys = document.getElementsByTagName('body');
    if (bodys.length) {
        bodys.item(0).appendChild(handle);
    }
}

function create_handle() {
    var wrapper = document.createElement('div');
    wrapper.innerHTML = template;
    handle = wrapper.children[0];
    prepend_style();
    append();
    wrapper = null;
}

function set_text(x) {
    if (!text_handle) {
        if (document.querySelector) {
            text_handle = document.querySelector('.loader-element-text-content-global');
            text_container = document.querySelector('.loader-element-text-global');
        }
    }
    if (text_handle) {
        var xx = U.NEString(x, null);
        if (xx) {
            text_handle.innerText = xx;
            text_container.style.display = 'block';
        } else {
            text_handle.innerText = '';
            text_container.style.display = 'none';
        }
    }   
}

P.init = function () {
    H = this;
    create_handle();
    return this;
};
P.show = function () {
    counter++;
    if (counter > 0) {
        handle.style.display = 'block';
        append();
        set_text('');
    }
    return this;
};
P.set_text = function (text) {
    set_text(text);
    return this;
};
P.hide = function () {
    counter--;
    if (counter < 0) {
        counter = 0;
    }
    if (counter === 0) {
        handle.style.display = 'none';
    }
    return this;
};
export {global_preloader};