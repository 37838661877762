import U from './lib-utils';
import {Value, InvalidValue, EmptyValue} from './Filter/Value';
import {filterDescriptor} from './Filter/filterDescriptor';
import {filterInfo} from './Filter/filterInfo';
var H = null;

function Filter() {
    return Filter.is(H) ? H : ((Filter.is(this) ? this.init : Filter.F).apply(this, Array.prototype.slice.call(arguments)));
}

var P = U.fixup_constructor(Filter).prototype;


P.init = function () {
    H = this;
    return this;
};


P.is_value = function (x) {
    return Value.is(x);
};

P.isValue = P.is_value;

P.is_invalid_value = function (x) {
    return InvalidValue.is(x);
};
P.isInvalidValue = P.is_invalid_value;
P.is_empty_value = function (x) {
    return EmptyValue.is(x);
};
P.isEmptyValue = P.is_empty_value;

/**
 * 
 * 
 * @param {Any} v value
 * @param {Any} f any object convertable to array of filterInfo (#filterInfo.parse_filter_info)
 * @returns {Any}
 */
P.apply_filter_to_value = function (v, f) {
    var filter_list = filterInfo.parse_filter_info(f);
    var current_value = v;
    for (var i = 0; i < filter_list.length; i++) {
        current_value = filter_list[i].apply_filter(v);
    }
    return current_value;

};
P.applyFilterToValue = P.apply_filter_to_value;
P.apply_filter = P.apply_filter_to_value;
P.applyFilter = P.apply_filter_to_value;

/**
 * 
 * @param {array} va
 * @param {any} f any type convertable to array of filterInfo (#filterInfo.parse_filter_info)
 * @param {Boolean} skip default true. skip invalid members or throw error
 * @returns {any}
 */
P.apply_filter_to_each_in = function (va, f, skip) {
    var sa = U.safe_array(va);
    var r = [];
    skip = U.any_bool(skip, true);
    var filter_list = filterInfo.parse_filter_info(f);
    for (var i = 0; i < sa.length; i++) {
        var current_value = sa[i];
        for (var j = 0; j < filter_list.length; j++) {
            current_value = filter_list[i].apply_filter(current_value);
            if (Value.is(current_value)) {
                if (!skip) {
                    this.throw_bad_value(current_value);
                }
            } else {
                r.push(current_value);
            }
        }
    }
    return r;
};


P.applyFilterToEachIn = P.apply_filter_to_each_in;

/**
 * 
 * @param {Object} vo
 * @param {any} desc any what can be cast to filterDescriptor, except json and XML representation
 * @returns {undefined}
 */
P.apply_hash = function (vo, desc) {
    var descriptor  =filterDescriptor(desc);
    var so = U.safe_object(vo);
    var ro = {};
    for(var i=0;i<descriptor.props.length;i++){
        var prop = descriptor.props[i];
        if(prop.in_name in so){
            var current_value = so[prop.in_name];
            for(var j=0;j<prop.filters.length;j++){
                current_value = prop.filters[j].apply_filter(current_value,prop.in_name);
            }
            ro[prop.out_name]=current_value;
        }
    }
    return ro;
};
P.applyHash = P.apply_hash;

P.apply = P.apply_hash;

P.apply_hash_xml = function(v,xml,path){
    return this.apply_hash(v,filterDescriptor.create_from_xml_string(xml,path));
};

P.apply_hash_json = function(v,json){
    return this.apply_hash(v,JSON.parse(json));
};

P.throw_bad_value = function (x) {
    if (Value.is(x)) {
        throw new Error(x.get_message());
    } else if (U.is_object(x)) {
        for (var k in x) {
            if (x.hasOwnProperty(k)) {
                if (Value.is(x[k])) {
                    throw new Error(x[k].get_message());
                }
            }
        }
    } else if (U.is_array(x)) {
    }
    for (var i = 0; i < x.length; i++) {
        if (Value.is(x[i])) {
            throw new Error(x[i].get_message);
        }
    }
    return this;
};

export default Filter;