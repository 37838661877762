import U from './lib-utils';

function callback(context,callable_fn) {
    return (callback.is(this) ? this.init : callback.F).apply(this, Array.prototype.slice.call(arguments));
}

var P = U.fixup_constructor(callback).prototype;
P.callable = null;
P.context = null;
P.hashkey = null;
P.init = function (co, ca) {
    this.callable = U.select_callable(co, ca);
    this.context = U.select_object(co, ca);
    this.hashkey = [
        (this.context && U.is_callable(this.context['-lib-eve-utils-object-get-id']) ? this.context['-lib-eve-utils-object-get-id']() : 'n'),
        (this.callable ? this.callable['-lib-eve-utils-function-get-id']() : 'n')
    ].join('-');
    return this;
};
P.isValid = function () {
    return !!(this.callable);
};
Object.defineProperty(P, 'valid', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function () {
        return this.isValid();
    }
});

P.run = function () {
    try {
        this.callable.apply(U.select_object(this.context, window), Array.prototype.slice.call(arguments));
    } catch (e) {
        window.setTimeout(function(){
            throw e;
        },0);        
    }
};

P.run_with_errors = function () {
    this.callable.apply(U.select_object(this.context, window), Array.prototype.slice.call(arguments));
};

P.run_array = function (arrayArgs) {
    this.run.apply(this, U.safe_array(arrayArgs));
};

P.run_array_with_errors = function (arrayArgs) {
    this.run_with_errors.apply(this, U.safe_array(arrayArgs));
};

function callbackManager() {
    return (callbackManager.is(this) ? this.init : callbackManager.F).apply(this, Array.prototype.slice.call(arguments));
}

var PP = U.fixup_constructor(callbackManager).prototype;

PP.index = null;
PP.list = null;
PP.init = function () {
    this.index = {};
    this.list = [];
    return this;
};

function add_callback(cbx) {
    this.list.push(cbx);
    this.index[cbx.hashkey] = cbx;
}
function contains_callback(cbx) {
    return callback.is(this.index[cbx.hashkey]);
}
function remove_callback(cbx) {
    var contained = this.index[cbx.hashkey];
    if (contained) {
        var index = this.list.indexOf(contained);
        if (index >= 0) {
            this.list = this.list.slice(0, index).concat(this.list.slice(index + 1));
        }
        delete(this.index[cbx.hashkey]);
    }
}


PP.reset = function () {
    this.list = [];
    this.index = {};
    return this;
};

PP.clear = PP.reset;

PP.add = function (co, ca) {
    var cb = callback(co, ca);
    if (cb.valid) {
        if (contains_callback.apply(this, [cb])) {
            remove_callback.apply(this, [cb]);
        }
        add_callback.apply(this, [cb]);
    }
    return this;
};

PP.contains = function (co, ca) {
    var cb = callback(co, ca);
    if (cb.valid) {
        return contains_callback.apply(this, [cb]);
    }
    return false;
};

PP.remove = function (co, ca) {
    var cb = callback(co, ca);
    if (cb.valid) {
        if (contains_callback.apply(this, [cb])) {
            remove_callback.apply(this, [cb]);
        }
    }
    return this;
};

PP.run = function () {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < this.list.length; i++) {
        this.list[i].run_array(args);
    }
};

PP.run_array = function (array_args) {
    this.run.apply(this, U.safe_array(array_args));
};

PP.run_with_errors = function () {
    var args = Array.prototype.slice.call(arguments);
    for (var i = 0; i < this.list.length; i++) {
        this.list[i].run_array_with_errors(args);
    }
};


PP.run_array_with_errors = function (array_args) {
    this.run_with_errors.apply(this, U.safe_array(array_args));
};

export {callbackManager, callback};