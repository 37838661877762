import U from './../lib-utils';
import factory from './filterFactory';
import filterParam from './filterParam';

function is_string(x) {
    return !!(typeof (x) === 'string');
}


function get_filter_instance(filter_name) {
    if (U.is_callable(factory[filter_name])) {
        var fn = factory[filter_name];
        return fn();
    }
    throw new Error(['lib-filter: no filter defined `', filter_name, '`'].join(''));
}

export {is_string, get_filter_instance}