import {callbackManager, callback} from './lib-callback-manager';
import U from './lib-utils';
var STATES = {
    STATE_NOT_RESOLVED: 0,
    STATE_RESOLVED_SUCCESS: 1,
    STATE_RESOLVED_FAIL: 2
};
function on_success() {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.state = STATES.STATE_RESOLVED_SUCCESS;
        this.result = [].concat(Array.prototype.slice.call(arguments)).concat([this]);
        this.callbacks_before_success.run_array(this.result);
        this.callbacks_success.run_array(this.result);
        this.callbacks_after_success.run_array(this.result);
        clear.call(this);
    }
}


function on_fail() {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.state = STATES.STATE_RESOLVED_FAIL;
        this.result = [this].concat(Array.prototype.slice.call(arguments));
        this.callbacks_before_fail.run_array(this.result);
        this.callbacks_fail.run_array(this.result);
        this.callbacks_after_fail.run_array(this.result);
        clear.call(this);
    }
}

function clear() {
    this.callbacks_before_success.reset();
    this.callbacks_success.reset();
    this.callbacks_after_success.reset();
    this.callbacks_before_fail.reset();
    this.callbacks_fail.reset();
    this.callbacks_after_fail.reset();
    this.callbacks_before_success = null;
    this.callbacks_success = null;
    this.callbacks_after_success = null;
    this.callbacks_before_fail = null;
    this.callbacks_fail = null;
    this.callbacks_after_fail = null;

}


function run_callback(co, ca) {
    var clb = callback(co, ca);
    if (clb.valid) {
        clb.run_array(this.result);
    }
}

function promise() {
    return (promise.is(this) ? this.init : promise.F).apply(this, Array.prototype.slice.call(arguments));
}
var P = U.fixup_constructor(promise).prototype;

P.state = STATES.STATE_NOT_RESOLVED;
P.result = null;
P.callbacks_before_success = null;
P.callbacks_success = null;
P.callbacks_after_success = null;
P.callbacks_before_fail = null;
P.callbacks_fail = null;
P.callbacks_after_fail = null;

P.init = function (co, wo) {
    var context = U.select_object(co, wo, window);
    var worker = U.select_callable(wo, co);
    this.callbacks_after_success = callbackManager();
    this.callbacks_before_success = callbackManager();
    this.callbacks_success = callbackManager();
    this.callbacks_before_fail = callbackManager();
    this.callbacks_fail = callbackManager();
    this.callbacks_after_fail = callbackManager();
    try {
        worker.apply(context, [on_success.bindTo(this), on_fail.bindTo(this)].concat(Array.prototype.slice.call(arguments, 2)));
    } catch (e) {
        on_fail.apply(this, [e]);
    }
    return this;
};


P.done = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_success.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_SUCCESS) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};
P.doneBefore = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_before_success.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_SUCCESS) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};
P.doneAfter = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_after_success.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_SUCCESS) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};

P.fail = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_fail.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_FAIL) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};
P.failBefore = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_before_fail.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_FAIL) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};
P.failAfter = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_after_fail.add(co, ca);
    } else if (this.state === STATES.STATE_RESOLVED_FAIL) {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};

P.always = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_success.add(co, ca);
        this.callbacks_fail.add(co, ca);
    } else {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};
P.alwaysBefore = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_before_success.add(co, ca);
        this.callbacks_before_fail.add(co, ca);
    } else {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};

P.alwaysAfter = function (co, ca) {
    if (this.state === STATES.STATE_NOT_RESOLVED) {
        this.callbacks_after_success.add(co, ca);
        this.callbacks_after_fail.add(co, ca);
    } else {
        run_callback.apply(this, [co, ca]);
    }
    return this;
};


function promiseCollection() {
    return (promiseCollection.is(this) ? this.init : promiseCollection.F).apply(this, Array.prototype.slice.call(arguments));
}

var PP = U.fixup_constructor(promiseCollection).prototype;

PP.promises = null;
PP.agg_promise = null;
PP.agg_success = null;
PP.agg_fail = null;
PP.total = 0;
PP.succeeed = 0;

function check_done() {
    if (this.total === this.promises.length) {
        if (this.succeeed === this.promises.length) {
            this.agg_success(this);
        } else {
            this.agg_fail(this);
        }
    }
}

PP.init = function (pl) {
    var proms = U.safe_array(pl);
    this.promises = [];
    this.agg_promise = promise(this, this.request_fn);
    for (var i = 0; i < proms.length; i++) {
        if (promise.is(proms[i])) {
            this.promises.push(proms[i]);
            proms[i].always(this, this.on_promise_done);
        }
    }
    if (!this.promises.length) {
        check_done.call(this);
    }
};



PP.on_promise_done = function (promise) {//1 параметр каллбака - промис,  остальные - как переданы
    this.total++;
    this.succeeed += (promise.state === STATES.STATE_RESOLVED_SUCCESS ? 1 : 0);
    check_done.call(this);
};

PP.request_fn = function (psuccess, pfail) {
    this.agg_success = psuccess;
    this.agg_fail = pfail;
    return this;
};

PP.done = function (co, ca) {
    this.agg_promise.done(co, ca);
    return this;
};

PP.doneBefore = function (co, ca) {
    this.agg_promise.doneBefore(co, ca);
    return this;
};

PP.doneAfter = function (co, ca) {
    this.agg_promise.doneAfter(co, ca);
    return this;
};

PP.fail = function (co, ca) {
    this.agg_promise.fail(co, ca);
    return this;
};

PP.failBefore = function (co, ca) {
    this.agg_promise.failBefore(co, ca);
    return this;
};

PP.failAfter = function (co, ca) {
    this.agg_promise.failAfter(co, ca);
    return this;
};

PP.always = function (co, ca) {
    this.agg_promise.always(co, ca);
    return this;
};
PP.alwaysBefore = function (co, ca) {
    this.agg_promise.alwaysBefore(co, ca);
    return this;
};
PP.alwaysAfter = function (co, ca) {
    this.agg_promise.alwaysAfter(co, ca);
    return this;
};

promise.wait_for = function () {
    var promises = Array.prototype.slice.call(arguments);
    return promiseCollection(promises);
};

promise.wait_for_array = function (proms) {
    var promises = U.safe_array(proms);
    return promiseCollection(promises);
};


export {promise, promiseCollection};



