import U from './../lib-utils';
import {property} from './property';

/**
 * 
 * @param {any} x  любое предатсавление свойств, совместимое с #property::parse_properties
 * @returns {filterDescriptor}
 */
function filterDescriptor(x) {
    return (filterDescriptor.is(this) ? this.init : filterDescriptor.F).apply(this, Array.prototype.slice.call(arguments));
}

var DP = U.fixup_constructor(filterDescriptor).prototype;

DP.props = null;

DP.init = function (x) {
    this.props = property.parse_properties(x);
    return this;
};


DP.each_property = function (co, ca) {
    var xca = U.select_callable(ca, co);
    var xco = U.select_object(co, ca);
    if (U.is_callable(xca)) {
        for (var i = 0; i < this.props.length; i++) {
            if (xco) {
                if (false === xca.apply(xco, [this.props[i], i, this])) {
                    break;
                }
            } else {
                if (false === xco(this.props[i], i, this)) {
                    break;
                }
            }
        }
    }
    return this;
};

DP.eachProperty = DP.each_property;
DP.each_prop = DP.each_property;
DP.eachProp = DP.each_property;



DP.add_property = function (x) {
    if (property.is(x) && x.is_valid()) {
        this.props.push(x);
    }
    return this;
};

DP.addProperty = DP.add_property;
DP.addProp = DP.add_property;
DP.add_prop = DP.add_property;

DP.get_properties = function () {
    return this.props;
};

DP.get_props = DP.get_properties;
DP.getProperties = DP.get_properties;
DP.getProps = DP.get_properties;


filterDescriptor.create_from_xml_string = function (xml, path) {
    if (!window.DOMParser) {
        throw new Error('lib-filter: DOMParser is not supported');
    }
    if (!window.XMLDocument) {
        throw new Error('lib-filter: XMLDocument is not supported');
    }
    var xml_doc = (new DOMParser()).parseFromString(xml, 'application/xml');
    if (xml_doc && (xml_doc instanceof XMLDocument)) {
        var t_node = xml_doc.documentElement;
        if (path) {
            var a_path = U.NEString(path).split('/');
            for (var i = 0; i < a_path.length; i++) {
                if (t_node) {
                    var pp = U.NEString(a_path[i], null);
                    if (pp) {
                        var nodes = t_node.getElementsByTagName(pp);
                        if (nodes && nodes.length) {
                            t_node = nodes.item(0);
                            continue;
                        }
                    }
                }
                t_node = null;
                break;
            }
        }
        if (t_node) {
            return filterDescriptor(t_node);
        } else {
            throw new Error(['lib-filter: invalid root path :`', path, '`'].join(''));
        }
    }
};

filterDescriptor.create_from_json_string = function (json_string) {
    return filterDescriptor(JSON.parse(json_string));
};

export {filterDescriptor};