<template>
    <div class="login-backdrop">
        <div class="login-window">
            <div class="login-window-title">
                <div class="login-window-title-text" v-text="TT('login_window.title')"></div>
            </div>
            <div class="login-window-body">
                <div class="login-window-body-content">
                    <div class="login-body-row">
                        <label for="login-window-login-control" v-text="TT('login_widow.login_label')"></label>
                        <div class="login-window-buttoned-input">
                            <input type="text" v-model="login" id="login-wibdow-login-control" />
                            <div class="login-window-buttoned-input-button" v-html="require(`!!raw-loader!./icons/login/login.svg`).default"></div>
                        </div>
                    </div>
                    <div class="login-body-row">
                        <label for="login-window-password-control" v-text="TT('login_widow.password_label')"></label>
                        <div class="login-window-buttoned-input">
                            <input type="password" v-model="password" id="login-wibdow-password-control" />
                            <div class="login-window-buttoned-input-button" v-html="require(`!!raw-loader!./icons/login/password.svg`).default"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login-window-footer">
                <div class="login-window-footer-content">
                    <div class="login-footer-button" v-text="TT('login_window.login_button')" v-on:click="do_login"></div>
                </div>
            </div>
            <preloader ref="loader"></preloader>            
        </div>
    </div>
</template>
<style scoped >
    .login-backdrop {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
        background:
            linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
            linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
            linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
            linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
            linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
            linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
        background-color: #131313;
        background-size: 20px 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: fixed;
    }

    .login-window {
        width: 100%;
        max-width: 320px;
        background: white;
        max-height: 70%;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 3px 3px 10px #444343;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;
    }

    .login-window-title {
        box-sizing: border-box;
        height: 2.5em;
        min-height: 2.5em;
        max-height: 2.5em;
        background: dodgerblue;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0 1em;
    }

    .login-window-title-text {
        font-size: 1.2em;
        color: white;
        font-weight: bold;
    }

    .login-window-footer {
        box-sizing: border-box;
        height: 3.5em;
        max-height: 3.5em;
        min-height: 3.5em;
        background: #2291ff40;
        padding: 0 .5em;
    }

    .login-window-body {
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
    }

    .login-window-footer-content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .login-footer-button {
        box-sizing: border-box;
        line-height: 2.5em;
        border: 1px solid dodgerblue;
        padding: 0 1.75em;
        color: white;
        background: dodgerblue;
        font-weight: bold;
        cursor: pointer;
        transition: all .3s;
    }

    .login-footer-button:hover {
        background: #066acd;
    }

    .login-window-body-content {
        box-sizing: border-box;
        padding: 1em 1.5em;
    }

    .login-body-row {
        box-sizing: border-box;
        margin-bottom: 1em;
    }

    .login-body-row label {
        display: block;
        margin-bottom: .2em;
        cursor: pointer;
        text-align: left;
    }

    .login-window-buttoned-input {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .login-window-buttoned-input input[type=text],.login-window-buttoned-input input[type=password] {
        box-sizing: border-box;
        padding: 0 .5em;
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        height: 2.5em;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid silver;
        cursor: pointer;
        transition: all .3s;
    }

    .login-window-buttoned-input input[type=text]:focus,.login-window-buttoned-input input[type=password]:focus{
        border-color:dodgerblue;
    }

    .login-window-buttoned-input input[type=text]:focus+.login-window-buttoned-input-button,
    .login-window-buttoned-input input[type=password]:focus+.login-window-buttoned-input-button{
        border-color:dodgerblue;
    }

    .login-window-buttoned-input input[type=text]:focus+.login-window-buttoned-input-button :deep(svg),
    .login-window-buttoned-input input[type=password]:focus+.login-window-buttoned-input-button :deep(svg){
        fill:dodgerblue;
    }

    .login-window-buttoned-input-button {
        box-sizing: border-box;
        border: 1px solid silver;
        width: 2.5em;
        height: 2.5em;
        border-left-width: 0;
        line-height: 0;
        padding: .5em;
        transition:all .3s;
    }

    .login-window-buttoned-input-button :deep(svg) {
        fill: silver;
        width:100%;
        height:100%;
    }

    .login-window-footer,
    .login-window-footer :deep(*),
    .login-window-title,
    .login-window-title :deep(*){
        user-select: none;
    }


</style>
<script>
    import U from '@/lib/vendor/eve/lib-utils';
    import cPreloader from '@/lib/vendor/eve/components/local-preloader';
    import Filter from '@/lib/vendor/eve/lib-filter';
    import {alert} from '@/lib/vendor/eve/lib-alert';
    import {request} from '@/lib/vendor/eve/lib-networking';

    export default {
        name: 'login',

        components: {
            preloader: cPreloader
        },
        data: function () {
            return {
                login: '',
                password: ''
            };
        },

        methods: {
            show_loader: function () {
                this.$.refs.loader.show();
                return this;
            },
            hide_loader: function () {
                this.$.refs.loader.hide();
                return this;
            },
            do_login: function () {
                try {
                    var data = Filter().apply_hash_xml(this.$data, require(`!!raw-loader!./validators/login.xml`).default, 'login/export');
                    Filter().throw_bad_value(data);
                } catch (e) {
                    alert({
                        close: true,
                        timeout: 5000,
                        title: this.TT(['login_window', 'error_title'].join('.')),
                        text: this.TT(['login_window', e.message].join('.')),
                        icon: 'preset:stop',
                        style: 'crimson'
                    }).show();
                    return;
                }
                request('POST', '/app/Auth/API?action=login', data, 'json', true, true)
                        .fail(this, this.on_login_fail)
                        .done(this, this.on_login_response)
                        .always(this, this.hide_loader);
            },
            on_login_fail: function (rq, p) {
                request.process_json_response(rq, this, this.on_login_success, this.show_network_error);

            },

            show_network_error: function (msg) {
                alert({
                    close: true,
                    timeout: 5000,
                    title: this.TT(['login_window', 'error_title'].join('.')),
                    text: this.TT(['login_window', msg].join('.')),
                    icon: 'preset:stop',
                    style: 'crimson'
                }).show();
            },
            on_login_response: function (rq, p) {
                request.process_json_response(rq, this, this.on_login_success, this.show_network_error);
            },
            on_login_success: function (data) {
                console.log(data);
            }
        }

    }
</script>