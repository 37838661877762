import * as VueRouter from 'vue-router';
import Login from '@/components/login';
import Main from '@/components/HelloWorld';
import U from '@/lib/vendor/eve/lib-utils';

export default VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes:[
        {
            path: '/',
            //name: 'main',
            component: Main,
            //props: true
        },
        {
            path: '/login',
            //name: 'login',
            component: Login,
            //props: true
        },
        {
            path: '/haha',
            //name: 'login',
            component: Login,
            //props: true
        }
    ]
});
window.UR=U;

//export default new VueRouter({
//    mode: 'history',
//    routes: [
//        {
//            path: '/',
//            name: 'main',
//            component: Main,
//            props: true
//        },
//        {
//            path: '/login',
//            name: 'login',
//            component: Login,
//            props: true
//        }
//    ]
//});
