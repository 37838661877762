require('@/assets/css/fonts.css');
require('@/assets/css/main.css');
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import U from './lib/vendor/eve/lib-utils';
import {NetworkManager, request} from './lib/vendor/eve/lib-networking';
import {translator} from './lib/vendor/eve/lib-translator';

translator('main').ready(function () {
    var app = createApp(App);
    app.use(router).use(translator());
    app.mount('#app');    
    router.app = app;    
    window.U = U;    
    var auth_token = U.NEString(localStorage.getItem('authorization'), null);

    NetworkManager().on_parsed_response(window, function (req, contentType, parsed_response) {
        if (contentType === 'application/json') {
            if (U.is_object(parsed_response) && parsed_response.status === 'auth') {
                if (!/^\/login/.test(router.currentRoute.value.path)) {
                    router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                    req.disable_callbacks = true;
                } else {
                    parsed_response.status = 'error';
                }
            }
        }
    }).on_open_request(window, function (request) {
        request.xhr.setRequestHeader('X-client-auth', auth_token);
    }).on_before_response(window, function (request) {
        var xt = U.NEString(request.xhr.getResponseHeader('x-client-auth'), null);
        if (xt) {
            auth_token = xt;
            localStorage.setItem('authorization', xt);
        }
    });

});


//request('GET', '/API/get_data', {x: 1, y: 2}).always(function () {
//    console.log(arguments);
//});
