import U from './../lib-utils';

function Value() {
    throw new Error('lib-filter:Value is abstract');
}

var VP = U.fixup_constructor(Value).prototype;
VP.message = null;
VP.get_message = function () {
    return this.message;
};
VP.getMessage = VP.get_message;

/**
 * 
 * @param {String} field_name
 * @returns {EmptyValue}
 */
function EmptyValue(field_name) {
    return (EmptyValue.is(this) ? this.init : EmptyValue.F).apply(this, Array.prototype.slice.call(arguments));
}
var EVP = EmptyValue.leu_extend(Value).prototype;

EVP.init = function (field_name) {
    var fn = U.NEString(field_name);
    this.message = fn ? [fn, 'ValueIsEmpty'].join(':') : 'ValueIsEmpty';
    return this;
};
/**
 * 
 * @param {String} field_name
 * @param {String} message
 * @returns {InvalidValue}
 */
function InvalidValue(field_name, message) {
    return (InvalidValue.is(this) ? this.init : InvalidValue.F).apply(this, Array.prototype.slice.call(arguments));
}

var IVP = InvalidValue.leu_extend(Value).prototype;

IVP.init = function (field_name, messageText) {
    var fn = U.NEString(field_name, null);
    var message = U.NEString(messageText, 'InvalidValue');
    this.message = fn ? [fn, message].join(':') : message;
    return this;
};

export {Value, EmptyValue, InvalidValue};