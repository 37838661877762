import U from './lib-utils';
import {callbackManager} from './lib-callback-manager';
import {promise} from './lib-promise';
var H = null, // singleton instance
        before_request = callbackManager(), // runs before request open. can modify url, request POST/GET body, but not headers. can cancel request
        open_request = callbackManager(), // pre-request operations. can modify headers, request POST body. but not url(GET BODY).can cancel request
        pre_response = callbackManager(), // response preprocessors. can access to response body before requestor. can cancel requestor processing (and another preprocessors)
        parsed_response = callbackManager(), // response preprocessors. can access to response body before requestor. can cancel requestor processing (and another preprocessors)
        post_response = callbackManager(), // response postprocessors - can access to response body, but cant cancel anything (except other postprocessors)
        request_start_monitor = callbackManager(), // получатели уведомления о начале реквеста
        request_end_monitor = callbackManager(), // получаели уведомления об окончании реквеста
        PRIMARY_URL = U.NEString(process.env.VUE_APP_PRIMARY_HOST, '');

var ENCODERS = {
    url: {
        get_content_type: function () {
            return 'application/x-www-form-urlencoded';
        },
        encode: function (xo) {
            function encodei(element, key, list) {
                var list = list || [];
                if (typeof (element) === 'object') {
                    for (var idx in element) {
                        encodei(element[idx], key ? key + '[' + idx + ']' : idx, list);
                    }
                } else {
                    list.push(key + '=' + encodeURIComponent(element));
                }
                return list.join('&');
            }
            return encodei(xo);
        }
    },
    json: {
        get_content_type: function () {
            return 'application/json';
        },
        encode: function (xo) {
            return JSON.stringify(xo);
        }
    }
};

var DECODERS = {
    'application/json': {
        decode: function (x) {
            return JSON.parse(x);
        }
    }
};

function NetworkManager() {// global singleton
    return H ? H : ((NetworkManager.is(this) ? this.init : NetworkManager.F).apply(this, Array.prototype.slice.call(arguments)));
}
var P = U.fixup_constructor(NetworkManager).prototype;

function remove_callback(stack, co, ca) {
    stack.remove(co, ca);
}

function add_callback(stack, co, ca) {
    stack.add(co, ca);
}

P.crossOriginCredentials = false;

P.init = function () {
    H = this;
    return this;
};

P.set_cross_origin_credentials = function (x) {
    this.crossOriginCredentials = U.any_bool(x, false);
    return this;
};

P.on_before_request = function (co, ca) {
    add_callback(before_request, co, ca);
    return this;
};
P.off_before_request = function (co, ca) {
    remove_callback(before_request, co, ca);
    return this;
};

P.on_open_request = function (co, ca) {
    add_callback(open_request, co, ca);
    return this;
};

P.off_open_request = function (co, ca) {
    remove_callback(open_request, co, ca);
    return this;
};

P.on_before_response = function (co, ca) {
    add_callback(pre_response, co, ca);
    return this;
};
P.off_before_response = function (co, ca) {
    remove_callback(pre_response, co, ca);
    return this;
};

P.on_after_response = function (co, ca) {
    add_callback(post_response, co, ca);
    return this;
};
P.off_after_response = function (co, ca) {
    remove_callback(post_response, co, ca);
    return this;
};
P.on_request_start = function (co, ca) {
    add_callback(request_start_monitor, co, ca);
    return this;
};

P.off_request_start = function (co, ca) {
    remove_callback(request_start_monitor, co, ca);
};
P.on_request_end = function (co, ca) {
    add_callback(request_end_monitor, co, ca);
    return this;
};

P.off_request_end = function (co, ca) {
    remove_callback(request_end_monitor, co, ca);
};


P.on_parsed_response = function (co, ca) {
    add_callback(parsed_response, co, ca);
    return this;
};

P.off_parsed_response = function (co, ca) {
    remove_callback(parsed_response, co, ca);
    return this;
};

P.get_decoder_list = function () {
    var r = [];
    for (var k in DECODERS) {
        if (DECODERS.hasOwnProperty(k) && U.is_object(DECODERS[k])) {
            r.push(k);
        }
    }
    return r;
};

P.get_encoder_list = function () {
    var r = [];
    for (var k in ENCODERS) {
        if (ENCODERS.hasOwnProperty(k) && U.is_object(ENCODERS[k])) {
            r.push(k);
        }
    }
    return r;
};

P.get_decoder = function (mime) {
    var mime_splat = this.decoder_mime_key(mime);
    if (this.has_decoder(mime_splat)) {
        return DECODERS[mime_splat];
    }
    return null;
};

P.isDecoder = function (obj) {
    if (U.is_object(obj)) {
        if (U.is_callable(obj.decode)) {
            return true;
        }
    }
    return false;
};

P.decoder_mime_key = function (mime) {
    return U.NEString(mime, '').split(';')[0].toLowerCase();
};

P.has_decoder = function (mime) {
    var mime_splat = this.decoder_mime_key(mime);
    if (DECODERS.hasOwnProperty(mime_splat) && this.isDecoder(DECODERS[mime_splat])) {
        return true;
    }
    return false;
};

P.isEncoder = function (obj) {
    if (U.is_object(obj)) {
        if (U.is_callable(obj.encode)) {
            if (U.is_callable(obj.get_content_type)) {
                return true;
            }
        }
    }
    return false;
};

P.has_encoder = function (term) {
    if (ENCODERS.hasOwnProperty(term) && this.isEncoder(ENCODERS[term])) {
        return true;
    }
    return false;
};

P.get_encoder = function (term) {
    if (this.has_encoder(term)) {
        return ENCODERS[term];
    }
    return null;
};

P.add_encoder = function (term, encoder) {
    var tt = U.NEString(term, null);
    if (tt) {
        if (this.isEncoder(encoder)) {
            ENCODERS[term.toLowerCase()] = encoder;
        }
    }
    return this;
};

P.add_decoder = function (mime, decoder) {
    var mime_splat = U.NEString(this.decoder_mime_key(mime), null);
    if (mime_splat) {
        if (this.isDecoder(decoder)) {
            DECODERS[mime_splat] = decoder;
        }
    }
    return this;
};







/**
 * 
 * @param {String} method
 * @param {String} url
 * @param {any} data if FormData - multipart
 * @param {String|Object} encoder json|url (ignores for form_data). if Object - encode method must be provided
 * @param {Boolean} withCredentials for cross-domain requests
 * @param {Boolean} disableMonitors do not report on_monitor_start,on_monitor_end callbacks
 * @returns {request.F|request.init}
 */
function request(method, url, data, encoder, withCredentials, disableMonitors) {
    return (request.is(this) ? this.init : request.F).apply(this, Array.prototype.slice.call(arguments));
}
var PP = U.fixup_constructor(request).prototype;

PP.promise = null;
PP.xhr = null;
PP.method = null;
PP.url = null;
PP.data = null;
PP._promiseSuccess = null;
PP._promiseFail = null;
PP.withCredentials = false;
PP.encoder = null;
PP.http_status = 0; // http статус операции
PP.raw_response = null; // чистый ответ
PP.parsed_response = null;// обработанный ответ, если есть декодер
PP.disableMonitors = false; // если true, то каллбаки request_start/request_end вызваны не будут
PP.disable_callbacks = false;// если true, то каллбаки промиса вызваны не будут
PP.init = function (method, url, data, encoder, withCredentials, disableMonitors) {
    this.method = /^post$/i.test(method) ? 'POST' : 'GET';
    this.url = U.NEString(url, '');
    if (!/^http/i.test(this.url) && !/^\/\//i.test(this.url)) {
        this.url = U.NEString(process.env.VUE_APP_PRIMARY_HOST, '').replace(/\/{0,}$/ig, '') + '/' + this.url.replace(/^\/{0,}/ig, '');
    }
    this.data = U.safe_object(data);
    this.withCredentials = U.any_bool(withCredentials, NetworkManager().crossOriginCredentials);
    this.disableMonitors = U.anyBool(disableMonitors, false);
    this.encoder = NetworkManager().isEncoder(encoder) ? encoder : this.create_encoder(encoder);
    this.promise = promise(this, this._run);
    return this;
};

PP.create_encoder = function (encoder_name) {
    encoder_name = U.NEString(encoder_name, 'url');
    var encoder = NetworkManager().get_encoder(encoder_name);
    if (NetworkManager().isEncoder(encoder)) {
        return encoder;
    }
    throw new Error(['-lib-eve-network: unknown encoder `', encoder_name, '`'].join(''));
};

PP._run = function (success, fail) {
    this._promiseSuccess = success;
    this._promiseFail = fail;
    this.runRequest();
    return this;
};

PP.runRequest = function () {
    var xhc = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
    this.xhr = new xhc();
    this.xhr.withCredentials = this.withCredentials;
    before_request.run_with_errors(this);
    this.xhr.open(this.method, this.method === 'POST' ? this.url : this.encodeUrl());
    open_request.run_with_errors(this);
    this.xhr.onreadystatechange = this.onreadystatechange.bindTo(this);
    if (window.FormData && (this.data instanceof FormData)) {
        if (!this.disableMonitors) {
            request_start_monitor.run();
        }
        this.method === 'POST' ? this.xhr.send(this.data) : this.xhr.send();
    } else {
        if (this.method === 'POST') {
            this.xhr.setRequestHeader('Content-Type', this.encoder.get_content_type());
            if (!this.disableMonitors) {
                request_start_monitor.run();
            }
            this.xhr.send(this.encoder.encode(this.data));
        } else {
            if (!this.disableMonitors) {
                request_start_monitor.run();
            }
            this.xhr.send();
        }
    }
    return this;
};

PP.onreadystatechange = function () {
    if (this.xhr.readyState === 4) {
        try {
            if (!this.disableMonitors) {
                request_end_monitor.run();
            }
            this.http_status = this.xhr.status;
            pre_response.run_with_errors(this);
            this.raw_response = this.xhr.responseText;
            if (this.xhr.status === 200) {
                var content_type = this.xhr.getResponseHeader('content-type');
                var splat = NetworkManager().decoder_mime_key(content_type);
                var decoder = NetworkManager().get_decoder(splat);
                if (decoder) {
                    this.parsed_response = decoder.decode(this.xhr.responseText);
                    parsed_response.run(this, splat, this.parsed_response);
                }
                if (!this.disable_callbacks) {
                    this._promiseSuccess(this);
                }
                post_response.run(this);
                return this;
                //throw new Error("MailformedResponce");
            } else {
                throw new Error(["NetworkError", this.xhr.status].join('.'));
            }
        } catch (e) {
            if (!this.disable_callbacks) {
                this._promiseFail(e);
            }
        }
        return this;
    }
    return this;
};

/**
 * хелпер обработки результата запроса.
 * проверит запрос и вызовет либо successFn с параметром rs.parsed_response
 * либо failFn с параметром "текст сообщения об ошибке"
 * @param {request|Error|String} rs
 * @param {Object|null} cx callable context
 * @param {function} successFn
 * @param {function} failFn
 * @returns {undefined}
 */
request.process_json_response = function (rs, cx, successFn, failFn) {
    if (request.is(rs)) {
        if (U.is_object(rs.parsed_response)) {
            if (rs.parsed_response.status === 'ok') {
                if (U.is_callable(successFn)) {
                    successFn.call(U.select_object(cx, window), rs.parsed_response);
                    return;
                }

            }
        }
    }
    if (U.is_callable(failFn)) {
        var message = null;
        if (request.is(rs)) {
            if (U.is_object(rs.parsed_response)) {
                if (rs.parsed_response.status === 'error') {
                    message = U.NEString(U.safe_object(rs.parsed_response.error_info).message, null);
                }
            }
        }
        if (!message) {
            if (U.is_object(rs) && (rs instanceof Error)) {
                message = rs.message;
            }
        }
        if (!message) {
            if (rs && (typeof (rs) === 'string')) {
                message = U.NEString(message, null);
            }
        }
        if (!message) {
            if (rs && U.is_object(rs) && rs.toString) {
                message = U.NEString(rs.toString(), null);
            }
        }
        failFn.call(U.select_object(cx, window), U.NEString(message, 'unknown_network_error'));
    }


};


PP.clear = function () {
    this.xhr = null;
    return this;
};
//<editor-fold defaultstate="collapsed" desc="Encoders">
PP.encodeUrl = function () {
    var urlData = NetworkManager().get_encoder('url').encode(this.data);
    return [this.url, urlData.length ? (this.url.indexOf('?') < 0 ? '?' : '&') : '', urlData].join('');
};

//</editor-fold>

//<editor-fold defaultstate="collapsed" desc="Promise proxies">
PP.done = function () {
    this.promise.done.apply(this.promise, Array.prototype.slice.call(arguments));
    return this;
};
PP.fail = function () {
    this.promise.fail.apply(this.promise, Array.prototype.slice.call(arguments));
    return this;
};
PP.always = function () {
    this.promise.always.apply(this.promise, Array.prototype.slice.call(arguments));
    return this;
};
//</editor-fold>





export {NetworkManager, request};
